@mixin flexAlignCenter {
    display: flex;
    align-items: center;
    justify-content: center;
};

@mixin links {
   text-decoration: none;
   color: $textColor;
   transition: all 0.2s ease-in-out;
   &:hover{
       color: $extraColor;
       opacity: 0.5;
   }

}

@mixin title{
        font-size: 2.5rem;
        text-transform: uppercase;
        margin: 1rem 0rem 4rem 0rem;
        letter-spacing: 0.15rem;
}

//INTRO
//heading
@mixin heading {
  color: $textColor;
  font-family: $familyFontsIntroPrimary;
}

@mixin headingSecond {
  @include heading;
  font-size: 5.6rem;
  font-weight: 700;
  margin-bottom: 0.8rem;
}

//paragraph
@mixin paragraphNormal {
  color: $paragraphExtraColor;
  font-size: 1.6rem;
  font-family: $familyFontsPrimary;
  line-height: 2.8rem;
}