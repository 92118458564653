.slider{
    position: relative;
     width: 100vw;
     height: 90vh;
    article{
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        h1{
            position: absolute;
            top: 20%;
            left: 40%;
        }
        img{
            width: 100%;
            transition: all 0.5s ease-in-out;
        }
    }
            #slideImg{
            transition: all 0.5s ease-in-out;
            animation: anime 3s infinite forwards;
        }
}

@keyframes anime {
    0%{
        opacity: 0;
    }
    100%{
        opacity:1;
    }
}