.offer{
    width: 80%;
    border-radius: 15px;
    margin-bottom: 2rem;
    font-family: $familyFontsSecondary;
    @include flexAlignCenter;
    flex-direction: column;
article{
    @include flexAlignCenter;
        padding: 2rem 5rem;
        h1{
        @include title;
        color: $textColor;
       strong{
            color: $extraColor;
       }
    }
    p{
        font-size: $descFont;
        font-weight: normal;
         strong{
            color: $extraColor;
       }
    }
      img{
         width: 100%;
    }
    .offerProducts{
        @include flexAlignCenter;
        justify-content: space-between;
        flex-wrap: wrap;
        img {
            width: 30%;
            padding: 2rem;
        }
    }
    .offerCards{
        margin: 5rem 0 2rem 0;
        a {
          text-decoration: none;
        }
    }
}

}


//INTRO
.intro {
  display: grid;
  grid-template-columns: 50% 50%;
  margin-top: 5rem;
  font-family: $familyFontsSecondary;
}

.introCard {
  height: 60vh;
  //animejs
  .introAnimationFirst .letter,
  .introAnimationSecond .letter {
    @include title;
    font-weight: 900;
    display: inline-block;
    text-align: center;
    color: $textColor;
  }

  .introAnimationFirst .letter {
    margin-top: 2.5rem;
    margin-bottom: 0rem;
  }

  p {
    font-size: $descFont;
    margin-bottom: 5.6rem;
  }
  &:first-child {
    text-align: left;
    width: 80%;
  }
  &:last-child {
    position: relative;
    text-align: right;
    img:first-of-type {
      height: auto;
      width: 110%;
    }

    img:last-of-type {
      position: absolute;
      top: 38%;
      right: -2%;
      height: 45vh;
       box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
  }
}


//media
@media screen and (max-width: 800px){
.intro {
  grid-template-columns: 100%;
  height: 100%;
}
.introCard {
    height: 100%;
  //animejs
  .introAnimationFirst .letter,
  .introAnimationSecond .letter {
    font-size: 2.2rem;
  }
     &:first-child {
    width: 100%;
  }
    &:last-child {
    img:last-of-type {
      width: auto;
      height: 50%;
      right: 5%;
      top: 25%;
    }
  }
}
}
@media screen and (max-width: 660px){
.introCard {
  //animejs
  .introAnimationFirst .letter,
  .introAnimationSecond .letter {
    font-size: 2rem;
    margin-top: 0;;
  }
  p{
     font-size: 1.8rem;
  }

    &:last-child {
      img:first-of-type {
      width: 100%;
      text-align: right;
    }
  }
}
}
@media screen and (max-width: 550px){
.intro {
  grid-template-columns: 100%;
  height: 100%;
}
.introCard{
  p{
    margin-bottom: 2rem;
  }
    &:last-child {
      img:first-of-type {
      width: 100%;
      border-radius: 10px;
    }
    img:last-of-type {
      display: none;
    }
  }
}
    .offer{
        background-color: #f5f5f5a3;
    border-radius: 0px;
    width: 100vw;
    margin-bottom: 0;
article{
    margin-bottom: 3rem;
    padding: 0rem 3rem;
    .offerCards{
      margin-top: 0rem;
    }
         p{
        font-size: 1.2rem;
        }
        h1 {
            font-size: 1.5rem;
        }
        h2{
            margin-top: 4rem;
            font-size: 1.2rem;
            text-transform: uppercase;
            letter-spacing: 0.3rem;
            border-bottom: 1px solid $extraColor;
        }
    .offerProducts{
        justify-content: center;
        flex-direction: column;
        img {
            width: 60%;
        }
    }
}

}
}
