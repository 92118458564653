.gallery {
  @include flexAlignCenter;
  flex-direction: column;
  width: 80%;
  h1 {
    @include title;
    color: $textColor;
    margin: 4rem 0;
  }
  .galleryImages {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-wrap: wrap;
    .galleryImage {
      flex: 25%;
      max-width: 25%;
      padding: 0.2rem;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

//modal
.modalContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.503);

  .modalImage {
    width: 70vw;
    margin: 8rem 6rem 8rem 6rem;
  }
}

.prevIcon,
.nextIcon,
.xIcon {
  position: absolute;
  width: 5rem;
  height: 5rem;
  margin: 0.5rem;
  padding: 1rem;
}

.prevIcon,
.nextIcon {
  top: 50%;
  transform: translateY(-50%);
}

.prevIcon {
  left: 5%;
}
.nextIcon {
  right: 5%;
}

.xIcon {
  top: 5%;
  right: 5%;
}

@media screen and (max-width: 1024px) {
  .gallery {
    .galleryImages {
      .galleryImage {
        flex: 33.33%;
        max-width: 33.33%;
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .gallery {
    width: 100%;
    h1 {
      font-size: 1.5rem;
    }
    .galleryImages {
      padding: 0;
      .galleryImage {
        flex: 50%;
        max-width: 50%;
      }
    }
  }
}
