* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%; //1rem = 10px, 1.6rem = 16px;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: $familyFontsPrimary;
  overflow-x: hidden;
}


.appContainer{
  @include flexAlignCenter;
  flex-direction: column;
    > section {
      padding: 4rem 2rem;
  }
}

