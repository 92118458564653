section  {
    @include flexAlignCenter;
    min-height: 30vh;
    width: 70%;
    h1{
        @include title;
        color: $bgColor;
        margin-bottom: 1rem;
    }
    h5{
        @include title; 
        color: $bgColor;
        margin-top: 0;
        font-size: 2rem;
        text-transform:lowercase;
        font-weight: 500;
        line-height: 3rem;
    }
}

//media

@media screen and (max-width: 1000px){
    .contactForm {
        width: 80%;
}
}

@media screen and (max-width: 600px){
    .contactForm {
        width: 100%;
    h1{
        font-size: 2rem;
        margin: 1rem 0rem 2rem 0rem;
    }
}
}