.navbar{
    @include flexAlignCenter;
    justify-content: space-between;
    padding: $sectionPadding;
    background-color: $bgColor;
    box-shadow: 0 1px 14px 0 rgba(0, 0, 0, 0.3), 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    &:first-child{
        img{
          width: 150px;       
        };
    };
    nav{
         a{
             @include links;
             color: $textColor;
             padding: 0 1.5rem;
             font-size: 1.8rem;
             text-transform: uppercase;
             transition: 0.3s transform ease-in-out;
             &:hover {
               opacity: 1;
             }
         };

    };

};

//nav-icon
.navicon {
  display:none;
  z-index: 95;
  cursor: pointer;
  position:absolute;
  right: 30px;
  top: 18px;
}

.navicon-btn {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  background-color: transparent;
  width: 4.5rem;
  cursor: pointer;
}

.navicon-span {
  z-index: 95;
  display: block;
  background: $textColor;
  transition: all 0.3s;
  position: relative;
  margin-top: 8px;
  height: 2.5px;
  width: 80%;
  float: right;
}

.active span:nth-child(1) {
  animation: ease 0.7s top forwards;
}

.not-active span:nth-child(1) {
  animation: ease 0.7s top-2 forwards;
}

.active span:nth-child(2) {
  animation: ease 0.7s scaled forwards;
}

.not-active span:nth-child(2) {
  animation: ease 0.7s scaled-2 forwards;
}

.active span:nth-child(3) {
  animation: ease 0.7s bottom forwards;
}

.not-active span:nth-child(3) {
  animation: ease 0.7s bottom-2 forwards;
}

//animations-keyframes

@keyframes top {
  0% {
    top: 0;
    transform: rotate(0);
  }
  50% {
    top: 1px;
    transform: rotate(0);
  }
  100% {
    top: 1px;
    transform: rotate(45deg);
  }
}

@keyframes top-2 {
  0% {
    top: 22px;
    transform: rotate(45deg);
  }
  50% {
    top: 22px;
    transform: rotate(0deg);
  }
  100% {
    top: 0;
    transform: rotate(0deg);
  }
}

@keyframes bottom {
  0% {
    width: 90%;
    bottom: 0;
    transform: rotate(0);
  }
  50% {
    bottom: 19px;
    transform: rotate(0);
  }
  100% {
    width: 80%;
    bottom: 19px;
    transform: rotate(135deg);
  }
}

@keyframes bottom-2 {
  0% {
    bottom: 22px;
    transform: rotate(135deg);
  }
  50% {
    bottom: 22px;
    transform: rotate(0);
  }
  100% {
    bottom: 0;
    transform: rotate(0);
  }
}

@keyframes scaled {
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes scaled-2 {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

//navbar-modal
    .navbarModal{
        @include flexAlignCenter;
        flex-direction: column;
        padding: $sectionPadding;
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 99;
        background-color:$bgColor;
        &:first-child{
            img{
                margin: 3rem 0.5rem;
                width: 150px;   
              };
    };
    nav{
           @include flexAlignCenter;
           flex-direction: column;
         a{
            @include links;
             padding: 1.5rem 0.5rem;
             font-size: 1.8rem;
             text-transform: uppercase;
             &:last-child {
                 margin-bottom: 2rem;
             }
         };

    };
};

//media
@media screen and (max-width: 850px) {
    .navbar{
        nav {
            display: none;
        }
        .modalBtn{
            position:absolute;
            right: 90px;
            top: 12px;
        }
    }
    //nav-icon
.navicon {
  display:flex;
}
}

@media screen and (max-width: 370px) {
    .navbar{
      padding: 1.5rem 2rem;
          &:first-child{
        img{
          width: 100px;       
        };
    };
       .modalBtn{
            right: 75px;
            top: 5px;
        }
    }
  .navicon {
  top: 10px;
  
}
}

/////////////////////
    header {
      a:last-of-type{
        text-decoration: none;
      }
      .active span:nth-child(1) {
            animation: none;
        
                }
    }