.card {
        flex: 100%;
        margin: 2rem 0;
        border-radius: 0px;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 3px 0 rgba(0, 0, 0, 0.19);
        background-color: $bgColor;
        color: $textColor;
        header{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        .imageContent{
            flex: 30%;
            img {
            height: 100%;
            }
        }
        .textContent{
            flex: 60%;
            .title{
                color: $textColor;
                font-size: $descFont;
                font-weight: 900;
                text-transform: uppercase;
                letter-spacing: 0.2rem;
                margin: 0;
                padding: 2rem 1rem 0rem 2rem;
        }

        .desc{
        font-size: 1.5rem;
        font-weight: normal;
        font-family: $familyFontsSecondary;
        padding: 0rem 2rem 1rem 2rem;
        margin: 0;
        margin-right: 2rem;
        }

        .listContainer{
        // background-color: #f3f3f3;
        color: $textColor;
        margin-top: 2rem;
        margin-right: 2rem;
        padding: 2rem;
        }
        }
        }
}

//media
//media
@media screen and (max-width: 990px){
.card{
    header{
        flex-direction: column;
    }
}
}