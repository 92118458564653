.outlined {
    &:hover{
    background-color: $textColor !important;
    color: $bgColor;
    border: none;
    };
};

.contained {
    &:hover{
    background-color: $extraColor !important;
    };
};