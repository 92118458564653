@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300&family=Poppins:wght@200&display=swap');
@import url('https://use.typekit.net/ywa6rkc.css');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa&display=swap');

//familyfonts
$familyFontsPrimary: 'Josefin Sans', sans-serif;
$familyFontsSecondary: 'Poppins', sans-serif;
$familyFontsIntroPrimary: 'Comfortaa', cursive;
$familyFontsIntroSecondary: 'all-round-gothic';
//fontSize
$descFont: 1.8rem;
//color
$textColor: #000;
$bgColor: #f5f5f5;
$paragraphExtraColor: #4a4764;
$extraColor: #cf3326;
$extraSecondColor: #008cba;
//padding
$sectionPadding: 1.5rem 4rem 1.5rem 4rem;