.about{
    width: 70%;
    flex-direction: column;
    font-family: $familyFontsSecondary;
    header{
        // background-color: #0e0e0ef5;
        background-image: url("../../assets/about-header.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position:50% 35%;
        box-shadow: 0 12px 18px 0 rgba(0, 0, 0, 0.2), 0 16px 20px 0 rgba(0, 0, 0, 0.19);
        border-radius: 0px;
        color: $bgColor;
        margin:2rem 0rem 8rem 0rem;
        padding: 2rem 4rem;
        width: 70vw;
        height: 70vh;
        p{
         @include flexAlignCenter;
         @include title;
         padding: 0 4rem;
         font-size: 2.2rem;
        text-align: center;
        font-weight: normal;
        height: 100%;
    }
    }
article{
    @include flexAlignCenter;
    flex-direction: column;
    .aboutText{
        h1{
        @include title;
        margin-bottom: 1rem;
        letter-spacing: normal;
        color: $textColor;
       strong{
            color: $extraColor;
       }
    }
    h1:last-of-type{
        margin-top: 5rem;
    }
    p{
        font-size: $descFont;
        font-weight: normal;
        strong{
                  color: $extraColor;
        }
    }
    }
    img{
         width: 100%;
    }
}

}

@media screen and (max-width: 550px){
    .about{
        header{
         p{
             font-size: 1.5rem;
             padding: 0rem;
             text-align: left;
         }
        }
    }
}

@media screen and (max-width: 330px){
    .about{
        header{
         width: 100vw;
        }
    }
}