footer {
  @include flexAlignCenter;
  height: 100%;
  background-color: $bgColor;
  font-size: 1.5rem;
  text-transform: uppercase;
  padding: 2rem;
  section {
    flex: 30%;
    margin: 1rem;
    @include flexAlignCenter;
    flex-direction: column;
    div {
      @include flexAlignCenter;
      flex-direction: column;
      align-items: flex-start;
      a {
        text-align: left;
        @include links;
        margin: 0.6rem;
      }
    }
    .logoFooter {
      width: 150px;
      height: auto;
      margin-bottom: 1rem;
    }
    .pfr {
      padding-top: 10px;
      width: 150px;
      height: auto;
    }
    .socialMediaFooter {
      @include flexAlignCenter;
      flex-direction: row;
      img {
        width: 25px;
        height: auto;
      }
    }
  }
}

.copyrightsFooter {
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 1.5rem;
  padding: $sectionPadding;
  background-color: $extraColor;
}

@media screen and (max-width: 700px) {
  footer {
    flex-direction: column;
    padding: 2rem 0 2rem 0;
    text-align: center;
    section {
      padding: 2rem 0 2rem 0;
      div {
        align-items: center;
      }
    }
  }
  .copyrightsFooter {
    font-size: 1rem;
  }
} ;
