.css-0 {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-10%);
  background-color: rgba(0, 0, 0, 0.109);
  span {
    background-color: $extraColor;
  }
}
