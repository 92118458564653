.news-modal {
  .modal-content {
    overflow-y: auto;

    .news-modal__close {
      font-size: 2rem;
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }
  &__close-button {
    span {
      font-size: 1.4rem;
    }
  }



  &__title {
    font-size: 5rem;
    font-weight: bold;
  }

  &__close {
    font-size: 2rem;
    position: absolute;
    right: 10px;
    top: 10px;
  }

  &__description {
    font-size: 2rem;
  }

  &__button-wrapper {
    justify-content: flex-end;

    button {
      margin: 10px 0;
      font-size: 1.4rem;
    }
  }
}
