.news {
  min-height: 100vh;
  width: 70%;
  margin: 2rem 0rem 8rem 0rem;
  padding: 2rem 4rem;
  flex-direction: column;
  font-family: $familyFontsSecondary;
  box-shadow: 0 12px 18px 0 rgba(0, 0, 0, 0.2),
    0 16px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 0px;
  color: $bgColor;
  font-weight: 900;
  header {
    p {
      @include flexAlignCenter;
      @include title;
      padding: 0 4rem;
      margin: 0;
      font-size: 2.2rem;
      text-align: center;
      font-weight: 400;
      height: 100%;
    }

    p:last-of-type {
      margin-bottom: 20px;
    }
  }
  article {
    @include flexAlignCenter;
    flex-direction: column;
    .newsText {
      h3 {
        @include title;
        text-transform: none;
        margin-bottom: 1rem;
        letter-spacing: normal;
        color: $bgColor;
        a {
          color: rgb(185, 0, 0);
        }
      }
      h3:last-of-type {
        margin-top: 5rem;
      }
      p {
        font-size: $descFont;
        font-weight: normal;
        strong {
          color: $extraColor;
        }
      }
      li {
        font-size: $descFont;
        font-weight: normal;
      }
    }
    img {
      width: 100%;
    }
    hr {
      max-width: 150px;
    }
  }
  small {
    text-transform: uppercase;
  }
}

.news-zaproszenie {
  width: 90vw;
  height: 75vh;
  margin-bottom: 5rem;
}

@media screen and (max-width: 550px) {
  .news {
    header {
      p {
        font-size: 1.5rem;
        padding: 0rem;
        text-align: left;
      }
    }
    article {
      .newsText {
        h3 {
          font-size: 1.8rem;
        }
      }
    }
  }

  .news-zaproszenie {
    width: 92vw;
    min-height: 50vh;
    margin-bottom: 3rem;
  }
}

@media screen and (max-width: 330px) {
  .news {
    width: 100vw;
    header {
      text-align: center;
    }
  }
}
