//home-jumbotron
.carousel {
  width: 100vw;
  // margin-top: -30px;
  .carousel-item {
            // margin: auto 0;
    .carousel-caption {
      box-shadow: inset 0 0 0 3000px #00000049;
      border-radius: 35px;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      // text-align: center;
      margin: 0 auto;
      margin-bottom: 12.5%;
      padding: 1.5rem;
      h1 {
        @include title;
        margin: auto 0;
        color: $bgColor;
        font-weight: 900;
        font-size: 5rem;
        // letter-spacing: 0.8rem;
        // line-height: 3.5rem;
        font-family: $familyFontsPrimary;
      }
      h2{
        @include title;
        color: $extraColor;
        margin: auto 0;
        font-size: 3rem;
        font-weight: 900;
      }
    }
  }

  .carousel-indicators {
    margin-bottom: 18%;
    li {
      height: 2rem;
      width: 2rem;
      background-color: $bgColor;
      border-radius: 50%;
      padding: 0;
      margin-left: 1rem;
      // border-bottom: 0.1rem solid $whiteBackground;
    }
  }
  .carousel-control-prev,
  .carousel-control-next {
    width: 10%;
    color: #000;
    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      background-color: $textColor;
      border-radius: 0%;
      width: 6rem;
      height: 6rem;
      background-size: 3rem;
    }
  }

  .banner-description-modal {
    text-decoration: underline;
    cursor: pointer;
    position: absolute;
    z-index: 99;
  }
}

//media
@media screen and (max-width: 1250px) {
  .carousel {
    // margin-top: -12rem;
  }
  .carousel-indicators {
    margin-bottom: 16%;
  }
}

@media screen and (max-width: 1050px) {
  .carousel {
    // margin-top: -6rem;
    .carousel-item {
      .carousel-caption {
        h1 {
          font-size: 4rem;
        }
        h2{
          font-size: 2.5rem;
        }
      }
    }
    .carousel-indicators {
      margin-bottom: 10%;
    }
  }
}

@media screen and (max-width: 890px) {
  .carousel {
    // margin-top: -4rem;
    .carousel-item {
      .carousel-caption {
        margin-bottom: 20%;
      }
    }
    .carousel-indicators {
      margin-bottom: 7%;
    }
  }
}

@media screen and (max-width: 725px) {
  .carousel {
    .carousel-item {
      .carousel-caption {
        h1 {
          font-size: 2.5rem;
        }

        h2{
          font-size: 1.6rem;
        }
      }
    }
    .carousel-control-prev,
    .carousel-control-next {
      .carousel-control-prev-icon,
      .carousel-control-next-icon {
        width: 4rem;
        height: 4rem;
        background-size: 2rem;
      }
    }
  }
}

@media screen and (max-width: 545px) {
  .carousel {
    // margin-top: -2rem;
    .carousel-item {
      .carousel-caption {
        margin-bottom: 15%;
      }
    }
    .carousel-indicators {
      margin-bottom: 5%;
    }
  }
}

@media screen and (max-width: 420px) {
  .carousel {
    .carousel-item {
      .carousel-caption {
        h1 {
          font-size: 1.5rem;
          font-weight: 600;
        }

        h2{
            font-size: 1rem;
        }
      }
    }
    .carousel-indicators {
      margin-bottom: 5%;
    }
  }
}

@media screen and (max-width: 345px) {
  .carousel {
    // margin-top: 0rem;
    .carousel-indicators {
      margin-bottom: 5%;
    }
  }
}
